import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import {toast} from 'react-toastify';
import {isEmpty, loginCheck} from "../Helpers/Helpers";
import {BACKEND_URL} from "../Helpers/Environment";
import axios from "axios";
import "./Login/login.css";
import {ReactComponent as LockIcon} from '../Components/icons/lock.svg';
import {ReactComponent as EyeIcon} from '../Components/icons/eye.svg';
import {ReactComponent as EyeClosedIcon} from '../Components/icons/eye-slash.svg';
import {useSearchParams} from "react-router-dom";
import {redirectTo} from "../navigation";

function ResetPasswordPage() {
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword_1, setNewPassword_1] = useState("");
    const [newPassword_2, setNewPassword_2] = useState("");
    const [queryParams] = useSearchParams();
    const [resetToken, setResetToken] = useState("");

    useEffect(() => {
        const token = queryParams.get('resetToken');
        setResetToken(token);
        if (isEmpty(token)) return redirectTo("/connect/login");
    }, []);

    const handleOnChange = (e, type, name) => {
        if (name === "password-1") {
            setNewPassword_1(e.target.value);
        } else if (name === "password-2") {
            setNewPassword_2(e.target.value);
        }
    }

    const resetPassword = () => {
        if (newPassword_1 !== newPassword_2) return toast.error("Εσφαλμένη επαλήθευση κωδικού.");
        if (newPassword_1.length < 10) return toast.error("Παρακαλώ εισάγετε κωδικό τουλάχιστον 10 χαρακτήρων.");

        const requestBody = {
            token: resetToken,
            password: newPassword_1,
        }

        axios.post(`${BACKEND_URL}/api/login/reset-credentials`, requestBody, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.data.status === "200") {
                toast.success("Ο νέος κωδικός αποθηκεύτηκε επιτυχώς.");
            } else {
                toast.error(res.data.message);
            }
            redirectTo("/connect/login");
        }).catch((err) => {
            console.log(err);
            toast.error("Server error.");
        })
    }

    const handleEnter = (e) => {
        if (e.code === "Enter") resetPassword();
    }

    return(
        <React.Fragment>
            <Container>
                <Row className={"mt-5"}>
                    <Col md={2}></Col>
                    <Col md={8} className={"px-lg-5"}>
                        <div className={"login-container"} onKeyDown={(e) => handleEnter(e)}>
                            <div className={"login-title mb-3"}>Επαναφορά Κωδικού Εμπόρου</div>
                            <div className={"login-logo"} style={{textAlign: "center", padding: "10px 0"}}>
                                <a href={"/"} style={{display: "inline-block"}}>
                                    <img style={{display: "block", marginLeft: "auto", marginRight: "auto", height: "80px"}}
                                         src={"/images/logo_primerweb.png"}
                                         alt={"Logo-Primer-Software"}/>
                                </a>
                            </div>
                            <div className={"login-content"}>
                                <InputGroup className="mb-2 input-container">
                                    <span className="login-icon input-group-text">
                                        <LockIcon width={22} height={22} />
                                    </span>
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Κωδικός"
                                        value={newPassword_1}
                                        className={"login-input"}
                                        onChange={(e) => handleOnChange(e, "input", "password-1")}
                                    />
                                    <span hidden={!showPassword} onClick={() => setShowPassword(!showPassword)} className={"eye-icon"}><EyeIcon width={22} height={22} viewBox={"0 2 24 24"} /></span>
                                    <span hidden={showPassword} onClick={() => setShowPassword(!showPassword)} className={"eye-icon"}><EyeClosedIcon width={22} height={22} viewBox={"0 2 24 24"} /></span>
                                </InputGroup>
                                <InputGroup className="mt-4 input-container">
                                    <span className="login-icon input-group-text">
                                        <LockIcon width={22} height={22} />
                                    </span>
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Επαλήθευση Κωδικού"
                                        value={newPassword_2}
                                        className={"login-input"}
                                        onChange={(e) => handleOnChange(e, "input", "password-2")}
                                    />
                                </InputGroup>
                                <div className={"text-center mt-5"}>
                                    <Button variant="primary" className={"login-button"} onClick={() => resetPassword()} disabled={isEmpty(newPassword_1) || isEmpty(newPassword_2)}>Επαναφορά</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default ResetPasswordPage;