import React, {useEffect, useRef, useState} from 'react';
import {BACKEND_URL} from "../../Helpers/Environment";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {UNSAFE_NavigationContext, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {toast} from 'react-toastify';
import {Modal, ProgressBar, Spinner} from "react-bootstrap";
import * as moment from "moment";
import {getAccessLevel, isEmpty, loginCheck} from "../../Helpers/Helpers";
import Dropdown from "../../Components/Dropdown";
import {invoiceTypesData} from "../../_data/mydata/invoiceTypes";
import {useDispatch, useSelector} from "react-redux";
import {
    addInvoiceSelection,
    removeInvoiceSelection,
    setCompanyACBoxDisplay,
    setOldRequestData,
    setRequestData,
    setResponseData,
    setSelectAll,
    setSelectedInvoices
} from "../../slices/searchInvoice_slice";
import DateBox from "../../Components/DateBox";
import DateHelper from "../../Components/DateHelper/DateHelper";
import InvoicePrintTemplate from "../../Components/InvoicePrint/invoicePrint";
import AdvancedCombobox from "../../Components/AdvancedCombobox";
import {exportExcelInvoices, exportFilteredInvoices, exportSelectedInvoices} from "../../Helpers/Export";
import {redirectTo} from "../../navigation";
import {SearchTable} from "../../Components/SearchTable";

function SearchInvoice() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const printRef = useRef();
    const location = useLocation();
    const isExportCanceledRef = useRef(false)
    const { navigator } = React.useContext(UNSAFE_NavigationContext);
    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [exportData, setExportData] = useState([]);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [invoiceTypeOptions, setInvoiceTypeOptions] = useState([]);
    const sendStatusOptions = [{label: "Χωρίς Κωδικό Αποτυχίας", value: "no"}, {label: "Transmission failure 1", value: 1}, {label: "Transmission failure 2", value: 2}];
    const [printData, setPrintData] = useState(null);
    const [exportChoice, setExportChoice] = useState();
    const [showExportTypeModal, setShowExportTypeModal] = useState(false);
    const filteredExportTypeOptions = [{label: "PDF", value: "pdf"}, {label: "Excel", value: "xlsx"}];
    const [filteredExportType, setFilteredExportType] = useState('pdf');
    const requestData = useSelector((state) => state.searchInvoice.requestData);
    const oldRequestData = useSelector((state) => state.searchInvoice.oldRequestData);
    const responseData = useSelector((state) => state.searchInvoice.responseData);
    const selectedInvoices = useSelector((state) => state.searchInvoice.selectedInvoices);
    const companyACBoxDisplay = useSelector((state) => state.searchInvoice.companyACBoxDisplay);
    const selectAll = useSelector((state) => state.searchInvoice.selectAll);
    const [pageChanged, setPageChanged] = useState(false);
    const [companyACBoxData, setCompanyACBoxData] = useState();
    const [progress, setProgress] = useState(0);
    const isAdmin = getAccessLevel() === "admin";
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const username = searchParams.get('url-vat');
        const password = searchParams.get('url-password');

        if(!loginCheck()) {
            if (!isEmpty(username) && !isEmpty(password)) {
                redirectTo(`/connect/login?url-vat=${username}&url-password=${password}`);
                return;
            }
            redirectTo("/connect/login?expired");
            return;
        }
        const invoiceTypeOptions = invoiceTypesData.map((obj, idx) => {
            return {label: `${obj.myCodeData} ${obj.name}`, value: obj.myCodeData}
        });
        setInvoiceTypeOptions(invoiceTypeOptions);

        if (isEmpty(companyACBoxData)) {
              fetchCompanyData().then((data) => {
                if (data?.data?.status === "200") setCompanyACBoxData(data.data.data);
              });
        }
        // if from redirect with state
        const selectedCompany = location.state?.selectedCompany;
        if (selectedCompany) {
            dispatch(setCompanyACBoxDisplay(`${selectedCompany.companyName} - ${selectedCompany.companyVatNumber}`));
            fetchInvoices(true);
        }
    }, [])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            if (selectAll) dispatch(setSelectAll(false));
            fetchInvoices();
        }
    }, [pageChanged])

    useEffect(() => {
        if (!loadingExport) return;

        const push = navigator.push;
        navigator.push = (...args) => {
            if (window.confirm("Αν εγκαταλήψετε τη σελίδα η λήψη θα διακοπεί. Θα διατηρήσετε μέρος των παραστατικών.")) {
                push(...args);
            }
        };
        return () => {
            navigator.push = push;
        };
    }, [loadingExport, navigator]);

    const tableColumns = [
        {
            name: "Επιλογή",
            field: "checkbox",
            width: "2%",
        },{
            name: "ΑΦΜ Εκδότη",
            field: "IssuerVAT",
            width: "5%",
        },{
            name: "MARK",
            field: "InvoiceMark",
            width: "8%",
        },{
            name: "UID",
            field: "InvoiceUid",
            width: "21%",
        },{
            name: "Τύπος Παραστατικού",
            field: "invoiceType",
            width: "20%",
        },{
            name: "Αρίθμηση",
            field: "numbering",
            width: "5%",
        },{
            name: "Ημ/νία έκδοσης",
            field: "InvoiceDate",
            width: "8%",
        },{
            name: "Συνολική καθαρή αξία",
            field: "totalNetValue",
            width: "7%",
            totalField: "genTotalNetValue",
        },{
            name: "Συνολική αξία Φ.Π.Α.",
            field: "totalVatValue",
            width: "7%",
            totalField: "genTotalVatValue",
        },{
            name: "Συνολική Αξία",
            field: "totalValue",
            width: "7%",
            totalField: "genTotalValue",
        },{
            name: "Αδυναμία Σύνδεσης",
            field: "transmissionFailure",
            width: "6%",
        },{
            name: "Προβολή",
            field: "actions",
            width: "4%",
        },
    ];

    const companyACBoxColumns = [
        {
            label: "Επωνυμία",
            name: "companyName"
        },{
            label: "Α.Φ.Μ.",
            name: "companyVatNumber"
        },{
            label: "Τηλέφωνο",
            name: "companyPhoneNumber"
        }
    ];

    const handleOnChange = (e, type, name) => {
        if (type === "input") {
            if (name === "companyVatNumber") {
                dispatch(setRequestData({...requestData, [name] : e.row.companyVatNumber}));
                dispatch(setCompanyACBoxDisplay(`${e.row.companyName} - ${e.row.companyVatNumber}`));
            } else {
                dispatch(setRequestData({...requestData, [name] : e.target.value}));
            }
        } else if (type === "date") {
            if (moment(e).isValid()) {
                dispatch(setRequestData({...requestData, [name] : moment(e).toString()}));
            } else {
                dispatch(setRequestData({...requestData, [name] : ""}));
            }
        } else if (type === "dropdown") {
            let codes = [];
            for (let v of e) {
                codes.push(v.value);
            }
            if (name === "invoiceType") {
                dispatch(setRequestData({...requestData, invoiceType: codes}));
            } else if (name === "fail_code") {
                dispatch(setRequestData({...requestData, fail_code: codes}));
            }
        } else if (name === "selectAll" ) {
            if (isEmpty(responseData.data)) return;
            const pageItems = responseData.data.map((el) => el.InvoiceUid);
            if (selectAll) {
                dispatch(setSelectedInvoices(selectedInvoices.filter((el) => !pageItems.includes(el))));
                dispatch(setSelectAll(false));
            } else {
                dispatch(setSelectedInvoices(Array.from(new Set([...selectedInvoices, ...pageItems]))))
                dispatch(setSelectAll(true));
            }
        }
    }

    const fetchInvoices = (force = false) => {
        if (force) dispatch(setRequestData({...requestData, page: 1}));
        setLoading(true);
        axios.get(`${BACKEND_URL}/api/public/get-invoices`, {
            headers: { "Content-Type": "application/json" },
            params: {
                ...(force ? requestData : oldRequestData),
                page: force ? 1 : oldRequestData.page,
                date_from: force ?
                    (!isEmpty(requestData.date_from) ? moment(requestData.date_from, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD") : null)
                    :
                    (!isEmpty(oldRequestData.date_from) ? moment(oldRequestData.date_from, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD") : null),
                date_to: force ?
                    (!isEmpty(requestData.date_to) ? moment(requestData.date_to, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD") : null)
                    :
                    (!isEmpty(oldRequestData.date_to) ? moment(oldRequestData.date_to, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD") : null),
                force: force,
            },
        }).then((res) => {
            setLoading(false);
            if (res.data.status === "200") {
                let prepObj = {...responseData,
                    data: res.data.data,
                    totals: res.data.totals ?? responseData.totals,
                    totalPages: res.data.totalPages
                }
                if (force) prepObj.totalItems = res.data.totalItems;

                dispatch(setResponseData(prepObj));
                if (force) {
                    dispatch(setOldRequestData(requestData));
                    dispatch(setSelectedInvoices([]))
                    dispatch(setSelectAll(false));
                }
                if (res.data.totalItems === 0 && force) return toast.info("Δε βρέθηκαν παραστατικά με αυτά τα κριτήρια.")
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            setLoading(false);
            console.error(err);
            toast.error("Internal server error.");
        })
    }

    const handleOnChangeSearch = async (keyword) => {
        dispatch(setCompanyACBoxDisplay(keyword));
        if(keyword.trim().length > 3) {
            let res = await fetchCompanyData(keyword);
            if (res.data.status === "200") {
                setCompanyACBoxData(res.data.data);
            } else {
                setCompanyACBoxData([]);
            }
        }
    }

    const handleOnClear = async () => {
        dispatch(setCompanyACBoxDisplay(""));
        dispatch(setRequestData({...requestData, companyVatNumber: ""}));
        let res = await fetchCompanyData();
        if (res?.data.status === "200") {
            setCompanyACBoxData(res.data.data);
        } else {
            setCompanyACBoxData([]);
        }
    }

    const handleExport = async (choice) => {
        setExportChoice(choice);
        setShowExportTypeModal(true)
    }

    const confirmExportFilteredInvoices = async () => {
        setLoadingExport(true);
        const res = await axios.post(`${BACKEND_URL}/api/public/fetch-remote-invoices`,
            requestData,
            {
                headers: {"Content-Type": "application/json"},
            }).catch((err) => {
            console.log(err);
            toast.error("Connection error.");
        });
        if (res.data.status === "200") {
            if (isEmpty(res.data.data)) {
                setLoadingExport(false);
                return toast.info(res.data.message ?? "Δε βρέθηκαν παραστατικά με αυτά τα κριτήρια.")
            }
            setExportData(res.data.data);
        } else {
            toast.error(`${res.data.message}`);
        }
        setLoadingExport(false);
    }

    const handleExportFilteredInvoices = async () => {
        setLoadingExport(true);
        const data = exportData;
        setExportData([]);
        await exportFilteredInvoices(data, printRef, setPrintData, setProgress, isExportCanceledRef);
        setLoadingExport(false);
    }

    const handleExportSelectedInvoices = async () => {
        setLoadingExport(true);
        await exportSelectedInvoices(selectedInvoices, printRef, setPrintData, setProgress, isExportCanceledRef);
        setLoadingExport(false);
    }

    const confirmCancelExport = () => {
        setShowCancelModal(true);
    }

    const cancelExport = async () => {
        isExportCanceledRef.current = true;
        setShowCancelModal(false);
    }

    const fetchCompanyData = async (query) => {
        return await axios.get(`${BACKEND_URL}/api/public/get-companies`, {
            headers: { "Content-Type": "application/json" },
            params: isEmpty(query) ? null : { query: query },
        }).catch((err) => {
            console.error(err)
        })
    }

    const handleEnter = (e) => {
        if (e.code === "Enter") fetchInvoices(true);
    }

    const viewInvoice = (e, identifier) => {
        navigate(`/${identifier}`, { state: {} });
    }

    const handlePageChange = (page) => {
        dispatch(setOldRequestData({...oldRequestData, page: page}));
        setPageChanged(true);
    }

    const handleRowSelection = (e, id) => {
        if (e.target.classList.contains('tableActions')) return;
        if (selectedInvoices.includes(id)) {
            dispatch(removeInvoiceSelection(id))
        } else {
            dispatch(addInvoiceSelection(id))
        }
    }

    const handleExportTypeSelection = async () => {
        setShowExportTypeModal(false);
        if (exportChoice === "filtered") {

            if (filteredExportType === "pdf") {
                await confirmExportFilteredInvoices();
            } else if (filteredExportType === "xlsx") {
                setLoadingExport(true);
                const filterData = {...requestData};
                filterData.date_from = (!isEmpty(requestData.date_from) ? moment(requestData.date_from, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD") : null);
                filterData.date_to = (!isEmpty(requestData.date_to) ? moment(requestData.date_to, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD") : null);
                await exportExcelInvoices(filterData);
                setLoadingExport(false);
            }
        } else if (exportChoice === "selected") {

            if (filteredExportType === "pdf") {
                await handleExportSelectedInvoices();
            } else if (filteredExportType === "xlsx") {
                await exportExcelInvoices({selectedInvoices: selectedInvoices})
            }
        }
    }

    return(
        <React.Fragment>
        <Container onKeyDown={(e) => handleEnter(e)}>
            <Row className={"mt-3"}>
                <Col md={6}>
                    <span>MARK/UID</span>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Mark/UID"
                            onChange={(e) => handleOnChange(e, "input", "identifier")}
                            value={requestData.identifier}
                        />
                    </InputGroup>
                </Col>
                <Col md={2}>
                    <DateHelper
                        dateFromAndToFunction={(from, to) => {
                            dispatch(setRequestData({
                                ...requestData,
                                date_from: from.toString(),
                                date_to: to.toString()
                            }))
                        }}
                    />
                </Col>
                <Col md={2}>
                    <span>Ημερομηνία από</span>
                    <InputGroup className="mb-3 w-100">
                        <DateBox
                            placeholder={"dd/mm/yyyy"}
                            minDate={new Date("01/01/2021")}
                            maxDate={new Date("01/01/2030")}
                            onChange={(e) => handleOnChange(e, "date", "date_from")}
                            selected={requestData.date_from}
                            dateFormat={"dd/MM/yyyy"}
                            classes={"w-100"}
                        />
                    </InputGroup>
                </Col>
                <Col md={2}>
                    <span>Ημερομηνία εως</span>
                    <InputGroup className="mb-3 w-100">
                        <DateBox
                            placeholder={"dd/mm/yyyy"}
                            minDate={new Date("01/01/2021")}
                            maxDate={new Date("01/01/2030")}
                            onChange={(e) => handleOnChange(e, "date", "date_to")}
                            selected={requestData.date_to}
                            dateFormat={"dd/MM/yyyy"}
                            classes={"w-100"}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <span>Αριθμός</span>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type={"number"}
                            placeholder="Από"
                            onChange={(e) => handleOnChange(e, "input", "number_from")}
                            value={requestData.number_from}
                        />
                        <Form.Control
                            type={"number"}
                            placeholder="Μέχρι"
                            onChange={(e) => handleOnChange(e, "input", "number_to")}
                            value={requestData.number_to}
                        />
                    </InputGroup>
                </Col>
                <Col md={3}>
                    <span>Συνολική αξία</span>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type={"number"}
                            placeholder="Από"
                            onChange={(e) => handleOnChange(e, "input", "total_from")}
                            value={requestData.total_from}
                        />
                        <Form.Control
                            type={"number"}
                            placeholder="Μέχρι"
                            onChange={(e) => handleOnChange(e, "input", "total_to")}
                            value={requestData.total_to}
                        />
                    </InputGroup>
                </Col>
                <Col md={6}>
                    <Dropdown
                        name={"invoice_type"}
                        label={"Τύπος Παραστατικού"}
                        placeholder={"Τύπος Παραστατικού"}
                        multiSelect={true}
                        options={invoiceTypeOptions}
                        isSearchable={false}
                        key={Math.random()}
                        defaultValue={invoiceTypeOptions.filter((el) => requestData.invoiceType.includes(el.value))}
                        onChange={(e) => handleOnChange(e, "dropdown", "invoiceType")}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Dropdown
                        key={Math.random()}
                        name={"fail_code"}
                        label={"Κωδικός Αποτυχίας"}
                        multiSelect={true}
                        options={sendStatusOptions}
                        isSearchable={false}
                        defaultValue={sendStatusOptions.filter((el) => requestData.fail_code.includes(el.value))}
                        onChange={(e) => handleOnChange(e, "dropdown", "fail_code")}
                    />
                </Col>
            {isAdmin && <Col md={6}>
                    <span>Φίλτρο Εταιρείας</span>
                    <InputGroup className="mb-3 w-100">
                        <AdvancedCombobox
                            placeholder="Επωνυμία / Α.Φ.Μ."
                            editEnable={false}
                            deleteEnable={false}
                            searchTableEnable={false}
                            searchableDropdownEnable={false}
                            showTableOptionOnSelect={true}
                            defaultValue={companyACBoxDisplay}
                            data={companyACBoxData}
                            columns={companyACBoxColumns}
                            onChange={(e) => handleOnChange(e, "input", "companyVatNumber")}
                            onChangeSearch={handleOnChangeSearch}
                            onClear={handleOnClear}
                        />
                    </InputGroup>
                </Col>}
            </Row>
            <Row>
                <Col md={2}>
                    <Button variant="primary" className={"w-100"} onClick={() => fetchInvoices(true)} disabled={loading}>
                        Αναζήτηση {loading && <Spinner size={"sm"} variant={"dark"}/>}
                    </Button>
                </Col>
                {<Col md={3}>
                    <Button variant="primary" style={{padding: "15px 20px"}} className={"w-100 mt-2 mt-md-0"}
                            onClick={() => handleExport("filtered")} disabled={loadingExport}>
                        {"Εξαγωγή Φιλτραρισμένων"}
                        {loadingExport && <Spinner size={"sm"} variant={"dark"}/>}
                    </Button>
                </Col>}
                 <Col md={3} style={{height: "64px"}}>
                     {!isEmpty(selectedInvoices) && <Button variant="primary" style={{padding: "15px 25px"}} className={"w-100 mt-2 mt-md-0"}
                            onClick={() => handleExport("selected")} disabled={loadingExport}>
                        {selectedInvoices.length > 1 ? `Εξαγωγή ${selectedInvoices.length} επιλεγμένων` : "Εξαγωγή επιλογής"}
                        {loadingExport && <Spinner size={"sm"} variant={"dark"}/>}
                    </Button>}
                </Col>
                {(loadingExport && exportData.length > 0) && <Col md={4}>
                    <div>
                        <span style={{fontSize: "12px"}}>Λήψη Αρχείων. Παρακαλώ Περιμένετε.</span>
                        &nbsp;&nbsp;
                        <span onClick={confirmCancelExport} className={"text-danger p-1 text-decoration-underline"} style={{fontSize: 14, cursor: "pointer"}}>Ακύρωση</span>
                    </div>
                    <ProgressBar
                        label={`${progress}%`}
                        min={0}
                        max={100}
                        now={progress}
                        animated={true}
                    />
                </Col>}
            </Row>
        </Container>

        <SearchTable
            responseData={responseData}
            tableColumns={tableColumns}
            activePage={oldRequestData.page}
            handlePageChange={handlePageChange}
            handleOnChange={handleOnChange}
            handleRowSelection={handleRowSelection}
            handleViewItem={viewInvoice}
            handleDoubleClickRow={viewInvoice}
            selectedItems={selectedInvoices}
            selectionIdentifier={"InvoiceUid"}
            selectAll={selectAll}
            showTotals={true}
            minimumWidth={"1800px"}
        />

        <Modal show={showExportTypeModal} onHide={() => setShowExportTypeModal(false)} centered={true}>
            <Modal.Header closeButton>
                <strong>Επιλέξτε τον τύπο αρχείου που επιθυμείτε.</strong>
            </Modal.Header>
            <Modal.Body>
                <Row className={"mx-auto mt-3"}>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <Dropdown
                            key={Math.random()}
                            name={"file_type"}
                            label={"Τύπος Αρχείου"}
                            multiSelect={false}
                            options={filteredExportTypeOptions}
                            isSearchable={false}
                            value={filteredExportTypeOptions.filter((el) => el.value === filteredExportType)}
                            onChange={(e) => setFilteredExportType(e.value)}
                        />
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className={"w-100 d-flex justify-content-between"}>
                    <Button variant="danger" onClick={() => setShowExportTypeModal(false)}>Ακύρωση</Button>
                    <Button variant="primary" onClick={() => handleExportTypeSelection()}>Συνέχεια</Button>
                </div>
            </Modal.Footer>
        </Modal>

        <Modal show={exportData.length > 0} onHide={() => setExportData([])} centered={true}>
            <Modal.Header closeButton>
                <strong>ΕΠΙΒΕΒΑΙΩΣΗ</strong>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    Πρόκειται να γίνει λήψη {exportData.length} αρχείων.
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className={"w-100 d-flex justify-content-between"}>
                    <Button variant="danger" onClick={() => setExportData([])}>Ακύρωση</Button>
                    <Button variant="primary" onClick={() => handleExportFilteredInvoices()}>Συνέχεια</Button>
                </div>
            </Modal.Footer>
        </Modal>
        <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)} centered={true}>
            <Modal.Header closeButton>
                <strong>ΑΚΥΡΩΣΗ ΛΗΨΗΣ</strong>
            </Modal.Header>
            <Modal.Body >
                Πρόκειται να ακυρώσετε τη λήψη παραστατικών.<br/>Θα διατηρηθούν τα παραστατικά που έχουν ήδη ληφθεί.
            </Modal.Body>
            <Modal.Footer>
                <div className={"w-100 d-flex justify-content-between"}>
                    <Button variant="danger" onClick={cancelExport}>Συνέχεια</Button>
                </div>
            </Modal.Footer>
        </Modal>
        <div style={{position: "absolute", left: "-9999px"}}>
            <InvoicePrintTemplate style={{display: "none"}} ref={printRef} data={printData} />
        </div>
        </React.Fragment>
    )
}

export default SearchInvoice;
