import React, {Suspense} from 'react';
import {BrowserRouter, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import NoPage from "./Pages/NoPage";
import Login from "./Pages/Login/Login";
import {ToastContainer} from 'react-toastify';
import {Provider} from 'react-redux';
import store from './store';
import MyDataSearch from "./Pages/MyDataSearch/MyDataSearch";
import SearchInvoice from "./Pages/SearchInvoice/SearchInvoice";
import Settings from "./Pages/Settings/Settings";
import {setNavigate} from "./navigation";
import ResetPasswordPage from "./Pages/ResetPasswordPage";


function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                    <AppWrapper />
            </BrowserRouter>
        </Provider>
    );
}

function AppWrapper() {
    const location = useLocation();
    const Navbar = React.lazy(() => import('./Components/Navbar'));
    const navigate = useNavigate();

    // We want to use navigation hook globally
    React.useEffect(() => {
        setNavigate(navigate);
    }, [navigate]);
    return (
        <React.Fragment>
            <Suspense fallback={<div style={{display: "none"}}></div>}>
                <Navbar currentPath={location.pathname} />
                <Routes>
                    <Route path="/">
                        <Route index element={<MyDataSearch />} />
                        <Route path="/:identifier" element={<MyDataSearch />} />
                        <Route path="connect/login" element={<Login />} />
                        <Route path="connect/search-invoice" element={<SearchInvoice />} />
                        <Route path="connect/settings" element={<Settings />} />
                        <Route path="connect/reset-form" element={<ResetPasswordPage />} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </Suspense>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={true}
            />
        </React.Fragment>
    )
}

export default App;
