import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import {Modal, Spinner} from "react-bootstrap";
import {toast} from 'react-toastify';
import {isEmpty, loginCheck, maskEmail} from "../../Helpers/Helpers";
import {BACKEND_URL} from "../../Helpers/Environment";
import axios from "axios";
import moment from "moment";
import "./login.css";
import {ReactComponent as UserIcon} from '../../Components/icons/user.svg';
import {ReactComponent as LockIcon} from '../../Components/icons/lock.svg';
import {ReactComponent as EyeIcon} from '../../Components/icons/eye.svg';
import {ReactComponent as EyeClosedIcon} from '../../Components/icons/eye-slash.svg';
import {useSearchParams} from "react-router-dom";
import {redirectTo} from "../../navigation";

function Login() {
    const [queryParams] = useSearchParams();
    const [reclaimPasswordModal, setReclaimPasswordModal] = useState(false);
    const [loginData, setLoginData] = useState({
        username: queryParams.get('url-vat') ?? "",
        password: queryParams.get('url-password') ?? "",
    });
    const [showPassword, setShowPassword] = useState(false);
    const [passwordResetVat, setPasswordResetVat] = useState("");
    const [resetEmail, setResetEmail] = useState();
    const [mailLoader, setMailLoader] = useState(false);

    useEffect(() => {
        if (!isEmpty(loginData.username) && !isEmpty(loginData.password)) {
            attemptLogin();
            return;
        }
        if (loginCheck()) redirectTo("/connect/search-invoice");
        if (queryParams.has("expired")) toast.info("Your token has expired. Please login again.")
    }, [])

    const attemptLogin = () => {
        if (isEmpty(loginData.username) || isEmpty(loginData.password)) {
            toast.error("Παρακαλώ εισάγετε ΑΦΜ Εμπόρου και κωδικό.");
        } else {
            axios.post(`${BACKEND_URL}/api/login/attempt-login`, {...loginData}, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                if (res.data.status === "200") {
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("expire_date", moment().add(1, "days").toString());
                    localStorage.setItem("company", JSON.stringify(res.data.company));
                    window.location.href = "/connect/search-invoice";
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Server error.");
            })
        }
    }

    const resetPassword = () => {
        setMailLoader(true);
        axios.post(`${BACKEND_URL}/api/login/reset-request`, {companyVatNumber: passwordResetVat}, {
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (res.data.status === "200") {
                setResetEmail(maskEmail(res.data.companyEmail));
            } else {
                toast.error(res.data.message);
            }
            setReclaimPasswordModal(false);
        }).catch((err) => {
            console.log(err);
            toast.error("Server error.");
        }).finally(() => setMailLoader(false))
    }

    const handleOnChange = (e, type, name) => {
        if (type === "input") {
            setLoginData({...loginData, [name] : e.target.value});
        }
    }

    const handleEnter = (e) => {
        if (e.code === "Enter") attemptLogin();
    }

    return (
        <React.Fragment>
            <Container>
                <Row className={"mt-5"}>
                    <Col md={2}></Col>
                    <Col md={8} className={"px-lg-5"}>
                        <div className={"login-container"} onKeyDown={(e) => handleEnter(e)}>
                        <div className={"login-title mb-3"}>Σύνδεση Εμπόρου</div>
                            <div className={"login-logo"} style={{textAlign: "center", padding: "10px 0"}}>
                                <a href={"/"} style={{display: "inline-block"}}>
                                <img style={{display: "block", marginLeft: "auto", marginRight: "auto", height: "80px"}}
                                    src={"/images/logo_primerweb.png"}
                                    alt={"Logo-Primer-Software"}/>
                                </a>
                            </div>
                            <div className={"login-content"}>
                                <InputGroup className="mb-4 input-container">
                                    <span className="login-icon input-group-text">
                                        <UserIcon width={22} height={22} />
                                    </span>
                                    <Form.Control
                                        placeholder="ΑΦΜ Εμπόρου"
                                        value={loginData.username}
                                        className={"login-input"}
                                        onChange={(e) => handleOnChange(e, "input", "username")}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-2 input-container">
                                    <span className="login-icon input-group-text">
                                        <LockIcon width={22} height={22} />
                                    </span>
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Κωδικός"
                                        value={loginData.password}
                                        className={"login-input"}
                                        onChange={(e) => handleOnChange(e, "input", "password")}
                                    />
                                    <span hidden={!showPassword} onClick={() => setShowPassword(!showPassword)} className={"eye-icon"}><EyeIcon width={22} height={22} viewBox={"0 2 24 24"} /></span>
                                    <span hidden={showPassword} onClick={() => setShowPassword(!showPassword)} className={"eye-icon"}><EyeClosedIcon width={22} height={22} viewBox={"0 2 24 24"} /></span>
                                </InputGroup>
                                <span className={"link"} onClick={() => setReclaimPasswordModal(true)}>Ανάκτηση Κωδικού</span>
                                <div className={"text-center"}>
                                    <Button variant="primary" className={"login-button"} onClick={() => attemptLogin()} disabled={isEmpty(loginData.username) || isEmpty(loginData.password)}>Σύνδεση</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
            <Modal show={reclaimPasswordModal} onHide={() => setReclaimPasswordModal(false)} centered={true}>
                <Modal.Header closeButton>
                    <strong>ΑΝΑΚΤΗΣΗ ΚΩΔΙΚΟΥ ΠΡΟΣΒΑΣΗΣ</strong>
                </Modal.Header>
                <Modal.Body className={"mt-2 mb-2"}>
                    <Row className={"mt-3"}>
                        <Col md={12}>
                            <p>Παρακαλώ συμληρώστε το Α.Φ.Μ εμπόρου και θα λάβετε email με το σύνδεσμο επαναφοράς.</p>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Α.Φ.Μ."
                                    onChange={(e) => setPasswordResetVat(e.target.value)}
                                    value={passwordResetVat}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="primary" onClick={resetPassword} disabled={isEmpty(passwordResetVat) || mailLoader}>
                            Συνέχεια{mailLoader && <Spinner size={"sm"} variant={"dark"}/>}
                        </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={!isEmpty(resetEmail)} onHide={() => setResetEmail(null)} centered={true}>
                <Modal.Header closeButton>
                    <strong>Στάλθηκε σύνδεσμος ανάκτησης κωδικού στο email:</strong>
                </Modal.Header>
                <Modal.Body className={"mt-2 mb-2"}>
                    <Row className={"mt-3"}>
                        <Col md={12}>
                            <p style={{fontSize: "20px", fontWeight: "500", textAlign: "center"}}>{resetEmail}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="primary" onClick={() => setResetEmail(null)}>Εντάξει</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default Login;
